<template>
      <router-view/>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style lang="scss">
html {
  scroll-padding-top: 200px; /* height of sticky header */
}

#app {
  font-family: "Avenir Next", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  -moz-osx-font-smoothing: grayscale;
}
.c-body-container {
  height: 100vh;
}

</style>
