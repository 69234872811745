import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import "./styles/global.scss";

import "popper.js";
import 'bootstrap/dist/css/bootstrap.css';
import BootstrapVue3 from 'bootstrap-vue-3'

import VueScrollTo from 'vue-scrollto'

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas);
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fab);
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(far);
import { dom } from "@fortawesome/fontawesome-svg-core";
dom.watch();

const app = createApp(App);

app.use(VueScrollTo)
app.use(BootstrapVue3)
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(router).mount('#app')

