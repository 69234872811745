import { createRouter, createWebHistory } from "vue-router";

const index = [
  {
    path: "/",
    name: "LandingPage",
    component: () => import("../views/layouts/LandingPage.vue"),
    children: [
      {
        path: "",
        name: "Home",
        component: () => import("../views/landing_page/Home.vue"),
      },
      {
        path: "/mentions-legales",
        name: "LegalPage",
        component: () => import("../views/legal_mention_page/LegalPage.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkExactActiveClass: "active",
  routes: index,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
});




export default router;
